import React from 'react';

import {SlideContent} from './slide-content';
import {Carousel} from 'react-responsive-carousel';
import {home as homeContent} from '../../../../../helpers/content';
import {useWindowSize} from '../../../../../hooks/use-window-size';
import './intro.css';

const crypto = require('crypto');

export const Intro = () => {
  // USE WINDOW SIZE HOOK
  const windowSize = useWindowSize();

  // RENDER
  return (
    <div className="intro">
      <div className="intro-main">
        <Carousel
          autoPlay
          className="intro-main-carousel"
          infiniteLoop
          interval={15000}
          showArrows={false}
          showStatus={false}
          showThumbs={false}
        >
          {homeContent.intro.carouselSlides.map(carouselSlide => {
            // BACKGROUND IMAGE
            let backgroundImage = carouselSlide.defaultBackgroundImagePath;

            // WINDOW SIZE
            if (windowSize.width <= 500) {
              backgroundImage = carouselSlide.smallBackgroundImagePath;
            }

            // DEVELOPER NOTE: carousel slides were broken up for greater control over specific changes to each slide
            return (
              <div
                className="intro-main-carousel-slide"
                key={crypto.randomBytes(20).toString('hex')}
                style={{
                  backgroundImage: `url(${backgroundImage})`,
                  position: 'relative',
                }}
              >
                <SlideContent
                  carouselSlide={carouselSlide}
                  rowHeading={carouselSlide.rowHeading}
                  columnHeading={carouselSlide.columnHeading}
                  hasButton={carouselSlide.hasButton}
                  increaseLogoSize={carouselSlide.increaseLogoSize}
                  fontColor={carouselSlide.color}
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};
